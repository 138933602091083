import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AppRoutingPaths } from '@base/app-routing.paths';
import { User } from '@base/models/classes/user.model';
import { AuthService } from '@base/services/auth/auth.service';
import { BooleanFieldType } from '@forms/base-boolean-field/base-boolean-field.component';
import { AppIcon } from '@models/enums/app.icons';
import { Theme } from '@models/enums/theme.enum';
import { UserRole } from '@models/enums/user-role.enum';
import { INavLink } from '@models/interfaces/nav-link.interface';
import { ThemeService } from '@services/theme/theme.service';

@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.scss']
})
export class LeftSideMenuComponent implements OnInit {
  @Input() header: string;
  @Input() showBackButton: boolean;
  @Input() backButtonUrl: string;

  readonly UserRole = UserRole;
  readonly AppIcon = AppIcon;
  readonly BooleanFieldType = BooleanFieldType;

  themeControl: FormControl<boolean>;
  themesEnabled: boolean = false;

  navigation: INavLink[] = [
    { icon: AppIcon.DASHBOARD, route: `/${this._getDashboardRoute()}`, name: 'NAVIGATION.DASHBOARD' },
    { icon: AppIcon.PROFILE, route: `/${this._getProfileRoute()}`, name: 'NAVIGATION.ACCOUNT' },
  ];

  get user(): User {
    return this._authService.me;
  }

  get selectedRole(): UserRole {
    return this._authService.mySelectedRole;
  }

  constructor(
    private _themeService: ThemeService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.themeControl = new FormControl<boolean>(this._themeService.theme === Theme.dark);
  }

  changeTheme(event: boolean): void {
    this._themeService.setTheme(event ? Theme.dark : Theme.light);
  }

  private _getDashboardRoute(): string {
    switch (this.selectedRole) {
      case UserRole.admin:
        return AppRoutingPaths.ADMIN_MODULES;
      case UserRole.coach:
        return AppRoutingPaths.COACH_DASHBOARD;
      case UserRole.user:
        return AppRoutingPaths.CLIENT_DASHBOARD;
      default:
        return AppRoutingPaths.CDN_NOT_FOUND;
    }
  }

  private _getProfileRoute(): string {
    // switch (this.selectedRole) {
    //   case UserRole.admin: return AppRoutingPaths.ADMIN_PROFILE;
    //   case UserRole.coach: return AppRoutingPaths.COACH_PROFILE;
    //   case UserRole.user: return AppRoutingPaths.CLIENT_PROFILE;
    //   default: return AppRoutingPaths.CDN_NOT_FOUND;
    // }
    return AppRoutingPaths.PROFILE;
  }
}
