/* eslint-disable max-len */
export enum AppRoutingPartials {
  AUTH = 'auth',
  ADMIN = 'admin',
  COACH = 'coach',
  USERS = 'users',

  SIGN_UP = 'register',
  LOGIN = 'login',
  SUCCESS = 'success',
  ERROR = 'error',
  INIT = 'init',
  VALIDATE = 'validate',
  RESET = 'reset',

  DASHBOARD = 'dashboard',
  MODULES = 'modules',
  OVERVIEW = 'overview',
  PLAYBOOK = 'playbook',
  PREVIEW = 'preview',
  PROFILE = 'profile',
  RECOVERY = 'recovery',
  SECURITY = 'security',
  TOOLS = 'tools',
  HELP = 'help',
  PRINT = 'print',
  ORGANIZATIONS = 'organizations',

  STEPS = 'steps',
  SITES = 'sites',

  // INFO: Admin only partials
  SITEMAP = 'sitemap'
}

export enum AppRoutingPaths {
  // Generics
  HOME = '/',
  // HEALTH = 'health',
  UNKNOWN = '**',

  // Generics: Auth Module
  AUTH = 'auth',
  AUTH_COGNITO_SUCCESS = 'success',
  AUTH_COGNITO_ERROR = 'error',
  AUTH_COGNITO_LOGIN = 'login',
  AUTH_COGNITO_SIGN_UP = 'register',
  // Generics Auth Module - Recover password
  AUTH_COGNITO_RECOVERY = 'recovery',
  AUTH_COGNITO_RECOVERY_INIT = 'init',
  AUTH_COGNITO_RECOVERY_VALIDATE = 'validate',
  AUTH_COGNITO_RECOVERY_RESET = 'reset',
  AUTH_COGNITO_RECOVERY_SUCCESS = 'success',
  // Generics Profile Module
  PROFILE = `${AppRoutingPartials.PROFILE}`,
  // CDN for static content
  CDN_NOT_FOUND = '404',
  CDN_NOT_IMPLEMENTED_YET = 'wip',
  CDN_IMPRINT = 'https://dquarks.com/?page_id=317',
  CDN_DATA_PRIVACY = 'https://dquarks.com/?page_id=314',

  // Client Section
  CLIENT_DASHBOARD = `${AppRoutingPartials.DASHBOARD}`,
  CLIENT_TRANSFORMATIONS = 'transformations',
  CLIENT_TRANSFORMATIONS_MODULE = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id`,
  CLIENT_TRANSFORMATIONS_MODULE_SITE = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id/${AppRoutingPartials.STEPS}/:stepId/${AppRoutingPartials.SITES}/:siteId`,
  CLIENT_TRANSFORMATIONS_MODULE_OVERVIEW = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id/${AppRoutingPartials.OVERVIEW}`,
  CLIENT_TRANSFORMATIONS_MODULE_PLAYBOOK = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id/${AppRoutingPartials.PLAYBOOK}`,
  CLIENT_TRANSFORMATIONS_MODULE_PLAYBOOK_OVERVIEW = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id/${AppRoutingPartials.PLAYBOOK}/${AppRoutingPartials.OVERVIEW}`,
  CLIENT_TRANSFORMATIONS_MODULE_PLAYBOOK_CHAPTER = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id/${AppRoutingPartials.PLAYBOOK}/:chapterId`,
  CLIENT_TRANSFORMATIONS_MODULE_PLAYBOOK_CHAPTER_ELEMENT = `${AppRoutingPaths.CLIENT_TRANSFORMATIONS}/:id/${AppRoutingPartials.PLAYBOOK}/:chapterId/:elementId`,

  // Admin Section
  // ADMIN_DASHBOARD = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.DASHBOARD}`,
  // Admin User Management
  ADMIN_USERS = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.USERS}`,
  // Admin Organization Management
  ADMIN_ORGANIZATIONS = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.ORGANIZATIONS}`,
  ADMIN_USERS_ADD = 'new',
  ADMIN_USERS_PROFILE = ':id/profile',
  ADMIN_USERS_PROFILE_EDIT = ':id/profile/edit',
  // Admin Module Management
  ADMIN_MODULES = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}`,
  ADMIN_MODULES_MODULE = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}/:id`,
  ADMIN_MODULES_MODULE_SITE = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}/:id/${AppRoutingPartials.SITES}/:siteId`,
  ADMIN_MODULES_MODULE_PREVIEW = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}/:id/${AppRoutingPartials.PREVIEW}`,
  ADMIN_MODULES_MODULE_PREVIEW_SITE = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}/:id/${AppRoutingPartials.PREVIEW}/${AppRoutingPartials.STEPS}/:stepId/${AppRoutingPartials.SITES}/:siteId`,
  ADMIN_MODULES_PLAYBOOK = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}/:id/${AppRoutingPartials.PLAYBOOK}`,
  ADMIN_MODULES_PLAYBOOK_PREVIEW = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.MODULES}/:id/${AppRoutingPartials.PLAYBOOK}/${AppRoutingPartials.PREVIEW}`,
  // ADMIN Sitemap
  ADMIN_SITEMAP = `${AppRoutingPartials.ADMIN}/${AppRoutingPartials.SITEMAP}`,

  // Coach section
  COACH_DASHBOARD = 'coach/dashboard',
  COACH_PROFILE = `${AppRoutingPartials.PROFILE}`
}
